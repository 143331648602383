<template>
  <div id="footer" class="container-fluid">
    <div class="container-fluid">
      <div class="section container-xxl">
        <div class="row align-items-start">
          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12">
            <h5>{{ $t("claim.opening.title") }}</h5>
            <table
              class="table table-sm table-borderless text-white"
              style="margin-left: -5px"
            >
              <tr>
                <td>{{ $t("claim.opening.monday") }}:</td>
                <td>{{ $t("claim.opening.mtime") }}</td>
              </tr>
              <tr>
                <td>{{ $t("claim.opening.di-fr") }}:</td>
                <td>{{ $t("claim.opening.time") }}</td>
              </tr>
            </table>
          </div>
          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12">
            <h5>{{ $t("claim.route.line1") }}</h5>
            <p>
              {{ $t("footer.practice") }}<br />
              Sihlweidstrasse 1<br />
              8041 {{ $t("footer.zh") }}
            </p>
            <p>
              <a
                href="https://www.google.ch/maps/dir//Sihlweidstrasse+1,+8041+Z%C3%BCrich/"
                target="_blank"
              >
                {{ $t("claim.route.title") }}
              </a>
            </p>
          </div>
          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12">
            <h5>{{ $t("nav.contact") }}</h5>
            <table
              class="table table-sm table-borderless text-white"
              style="margin-left: -5px; width: auto !important"
            >
              <tr>
                <td><i class="far fa-envelope"></i></td>
                <td><a href="mailto:praxis@forouzan.ch">praxis@forouzan.ch</a></td>
              </tr>
              <tr>
                <td><i class="far fa-phone-alt"></i></td>
                <td><a href="tel:+41444821437">+41 44 482 14 37</a></td>
              </tr>
              <tr>
                <td><i class="fab fa-instagram"></i></td>
                <td>
                  <a
                    href="https://www.instagram.com/zahnarzt_dr_forouzan/"
                    target="_blank"
                    >zahnarzt_dr_forouzan</a
                  >
                </td>
              </tr>
              <tr>
                <td><i class="fab fa-google"></i></td>
                <td>
                  <a
                    href="https://www.google.com/search?hl=de-CH&gl=ch&q=Zahnarztpraxis+Dr.+Forouzan,+Sihlweidstrasse+1,+8041+Z%C3%BCrich&ludocid=12616731869937774958&lsig=AB86z5UcoD-RTDrwnYZpNu3CIMwG&hl=de&gl=CH#lrd=0x479008327b53e8dd:0xaf17a2ca15fdb16e,1"
                    target="_blank"
                    >{{ $t("footer.reviews") }}</a
                  >
                </td>
              </tr>
            </table>
          </div>
          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12 d-none d-lg-block">
            <h5>Scan me!</h5>
            <img src="img/QRCode Contact.png" class="qrcode" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
.section {
  padding-top: 30px;
  padding-bottom: 30px;
}

.qrcode {
  width: 200px;
}
</style>
