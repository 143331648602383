<template>
    <Carousel :interval=10000 :slides="slides" />
    <Footer />
</template>

<script>
import Footer from '@/components/Footer.vue'
import Carousel from '@/components/Carousel.vue'
import Config from "@/config.json";
import Detect from "@/detect";

// https://github.com/darcyclarke/Detect.js
const ua = Detect.parse(navigator.userAgent);
//alert(ua.os.family);

let getWeek = function() {
  var date = new Date();
  date.setHours(0, 0, 0, 0);
  date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
  var week1 = new Date(date.getFullYear(), 0, 4);
  return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
}

export default {
  name: 'Home',
  components: {
    Footer,
    Carousel
  },
  data() {
    var weekNumber = getWeek()

    return {
      slides: Config.Campaigns[weekNumber]
    }
  }
}
</script>

<style scoped>

</style>