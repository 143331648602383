<template>
  <div
    id="carousel"
    class="carousel slide pointer-event d-none d-lg-block"
    data-bs-ride="carousel"
  >
    <div class="carousel-indicators">
      <button
        type="button"
        data-bs-target="#carousel"
        :data-bs-slide-to="index"
        :aria-label="$t('home.carousel.' + item + '.title')"
        :title="$t('home.carousel.' + item + '.title')"
        v-for="(item, index) in slides"
        :key="index"
        :class="{ active: index == 0 }"
      />
    </div>
    <div class="carousel-inner">
      <div
        class="carousel-item"
        v-for="(item, index) in slides"
        :key="index"
        :class="{ active: index == 0 }"
      >
        <div
          class="image"
          :style="{
            backgroundImage:
              'url(' + encodeURI($t('home.carousel.' + item + '.image')) + ')',
          }"
        ></div>
        <div class="carousel-caption d-none d-md-block">
          <h1>{{ $t("home.carousel." + item + ".title") }}</h1>
          <p v-html="$t('home.carousel.' + item + '.intro')"></p>
          <a
            role="button"
            class="btn btn-primary btn-lg"
            :href="$t('home.carousel.' + item + '.link')"
            >{{ $t("home.carousel." + item + ".button") }}</a
          >
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="container-xxl" v-for="(item, index) in slides" :key="index" :id="item">
      <h2 class="text-primary">{{ $t("home.carousel." + item + ".title") }}</h2>
      <h5><span v-html="$t('home.carousel.' + item + '.intro')"></span></h5>
      <p v-html="$t('home.carousel.' + item + '.text')"></p>
      <a
        role="button"
        class="btn btn-primary btn-lg"
        :href="$t('home.carousel.' + item + '.link')"
        >{{ $t("home.carousel." + item + ".button") }}</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "Carousel",
  props: {
    interval: { Number, default: 8000 },
    slides: Array,
  },
  mounted() {
    var myCarousel = document.querySelector("#carousel");
    var carousel = new bootstrap.Carousel(myCarousel, {
      interval: this.interval,
      wrap: true,
    });
  },
};
</script>

<style scoped>
#carousel .image {
  display: block;
  height: calc(100vh - 166px);
  background-color: aliceblue;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.carousel-caption {
  background-color: rgba(0, 0, 0, 0.25);
  padding: 50px;
}

h2 {
  margin-top: 50px;
}

.container-fluid {
  margin-bottom: 70px;
}
</style>
