<template>
  <div class="col-lg-3 col-md-3 col-sm-12" :title="label">
    <a v-if="link" :href="link" target="_blank">
      <table>
        <tr>
          <td class="icon">
            <i class="far" :class="icon"></i>
          </td>
          <td>
            <div class="text-primary">
              <b>{{ text1 }}</b>
            </div>
            <div class="text-black">{{ text2 }}</div>
          </td>
        </tr>
      </table>
    </a>
    <table v-else>
      <tr>
        <td class="icon">
          <i class="far" :class="icon"></i>
        </td>
        <td>
          <div class="text-primary">
            <b>{{ text1 }}</b>
          </div>
          <div class="text-black">{{ text2 }}</div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
    export default {
    name: "ClaimElement",
    props: {
        icon: String,
        label: String,
        text1: String,
        text2: String,
        link: String,
    },
    };
</script>

<style scoped>
    .icon {
    color: #192327b7;
    }

    a .icon {
    color: #378ac4 !important;
    }
</style>
