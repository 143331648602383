import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createI18n } from "vue-i18n";
import de from "@/locales/de.json";
import en from "@/locales/en.json";

const messages = {
  en: en,
  de: de,
};

function getLang() {
  if (localStorage.lang) {
    return localStorage.lang;
  } else {
    var lang = (navigator.language || navigator.userLanguage || "de").substring(0, 2);
    localStorage.lang = lang;
    return lang;
  }
}

const i18n = createI18n({
  locale: getLang(),
  fallbackLocale: "de",
  messages,
});

createApp(App)
  .use(router)
  .use(i18n)
  .mount("#app");
